<template>
    <div>
        <v-img
            class="mx-auto"
            src="../assets/img/logo2.png"
            width="120"
        ></v-img>
        <h3 class="text-center blue--text mt-3">إعــــدادات النظــام</h3>
        <v-card-text>
            <div class="d-flex justify-start">
                <span>
                    {{
                        $vuetify.theme.isDark ? "الوضع الفاتح" : "الوضع الداكن"
                    }}
                </span>
                <div class="mt-n1 mr-3"><themChanger /></div>
            </div>
        </v-card-text>
        <v-divider></v-divider>

        <v-card tile flat color="rgba(0, 0, 0, 0)" v-if="adminRole">
            <div class="py-4">
                <div class="mb-3 text-center blue--text">
                    اعدادات التلكرام
                </div>
                <v-btn
                    outlined
                    :disabled="items.length > 0"
                    color="blue"
                    @click="dialog = true"
                >
                    اضافة مفتاح الأمان للبوت واسم القناة
                </v-btn>
            </div>

            <v-card flat :loading="loading">
                <v-card-title v-for="(item, index) in items" :key="index">
                    <div class="d-flex justify-start">
                        <span class="ml-5">{{ index + 1 }} - </span>
                        <span>
                            {{
                                item.key == "tokenApi"
                                    ? " مفتاح الأمان للبوت"
                                    : "اسم القناة"
                            }}</span
                        >
                        <span class="mr-10 blue--text">{{ item.value }}</span>
                    </div>
                    <v-btn
                        @click="deleteToken(item.id)"
                        x-small
                        fab
                        class="mr-auto"
                        depressed
                    >
                        <v-icon color="error">
                            mdi-delete
                        </v-icon>
                    </v-btn>
                </v-card-title>
            </v-card>
        </v-card>

        <v-dialog v-model="dialog" width="800" persistent="">
            <v-card flat>
                <v-toolbar color="primary" dark>
                    <v-toolbar-title>
                        اضافة مفتاح الأمان للبوت واسم القناة
                    </v-toolbar-title>
                </v-toolbar>
                <v-col>
                    <v-form
                        @submit.prevent="submit()"
                        ref="form"
                        v-model="valid"
                        lazy-validation
                    >
                        <v-row no-gutters class="mt-4">
                            <v-col cols="12" md="6s" class="pl-2">
                                <small class="font-weight-bold">
                                    مفتاح الأمان للبوت
                                    <span class="red--text font-weight-bold"
                                        >*</span
                                    >
                                </small>
                                <v-text-field
                                    v-model="body.token"
                                    :rules="[required()]"
                                    required
                                    outlined
                                    dense
                                    color="blue"
                                    filled
                                    placeholder="token bot"
                                >
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" md="6" class="pl-2">
                                <small class="font-weight-bold">
                                    اسم القناة
                                    <span class="red--text font-weight-bold"
                                        >*</span
                                    >
                                </small>
                                <v-text-field
                                    v-model="body.chanel"
                                    :rules="[required()]"
                                    required
                                    outlined
                                    dense
                                    color="blue"
                                    filled
                                    placeholder="chanel name"
                                >
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" md="12" class="mt-2">
                                <v-btn
                                    type="submit"
                                    :disabled="!body.token || !body.chanel"
                                    :loading="loading"
                                    x-large
                                    color="primary"
                                >
                                    حفظ
                                </v-btn>
                                <v-btn
                                    @click="close()"
                                    x-large
                                    color="error"
                                    class="mr-2"
                                    outlined
                                >
                                    إلغاء
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
            </v-card>
        </v-dialog>

        <v-divider class="mt-12"></v-divider>
    </div>
</template>
<script>
import ThemChanger from "./layout/ThemeChanger.vue";
import validations from "@/utils/validations";
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            dialog: false,
            valid: true,
            body: {},
            items: [],
            loading: false,
            ...validations
        };
    },
    components: {
        ThemChanger
    },
    computed: {
        ...mapGetters("roles", ["adminRole"])
    },

    created() {
        this.getSettingTelegram();
    },

    methods: {
        getSettingTelegram() {
            this.loading = true;
            this.$GenericService
                .getAll("settingsSystem/getSettingTelegram")
                .then(res => {
                    if (res.data.data != null) this.items = res.data.data;

                    this.loading = false;
                })
                .catch(err => {});
        },

        submit() {
            this.loading = true;
            let data = {
                value: this.body.token,
                key: "tokenApi"
            };
            this.$GenericService
                .create("settingsSystem", data)
                .then(res => {
                    let data1 = {
                        value: this.body.chanel,
                        key: "chanelName"
                    };
                    this.$GenericService
                        .create("settingsSystem", data1)
                        .then(res => {});
                    this.close();
                    this.$store.dispatch("setSnackbar", {
                        text: `تم الإضافة بنجاح`
                    });
                    this.getSettingTelegram();
                })
                .catch(err => {
                    this.$store.dispatch("setSnackbar", {
                        text: ` ${err.response.data.message}`,
                        color: "error"
                    });
                })
                .finally(() => (this.loading = false));
        },

        deleteToken(id) {
            this.$GenericService.swalAlertConfirm({}).then(result => {
                if (result.isConfirmed) {
                    this.$GenericService
                        .delete(`settingsSystem/${id}`)
                        .then(res => {
                            this.getSettingTelegram();
                        })
                        .catch(err => {});
                    this.$GenericService.swalAlertSuccess();
                }
            });
        },

        close() {
            this.dialog = false;
            this.body = {};
            this.resetValidation();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        }
    }
};
</script>
